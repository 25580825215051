$('[data-aos]').each(function(){ $(this).addClass("aos-init"); });

var indexPage = new fullpage('#index-page', {
    verticalCentered : false,
    sectionsColor : ['#bdd7ee','#bdd7ee','#bdd7ee','#bdd7ee'],
    navigation: true,
    navigationPosition: 'right',

    afterRender: function(){
        var windoHeight = $( window ).width();
        if (windoHeight <= '375') {
            $('html').css('font-size', '12px');
            $('.root_daum_roughmap').hide();
            $('.map-link').show();
        }
    },
    onSlideLeave: function(){
        $('.slide [data-aos]').each(function(){
            $(this).removeClass("aos-animate")
        });
    },
    afterSlideLoad: function(){
        $('.slide.active [data-aos]').each(function(index, item){
            $(this).addClass("aos-animate")
        });
    },
    afterLoad: function() {
        $('.section.active [data-aos]').each(function () {
            $(this).addClass("aos-animate")
        });
    },
});

var servicePage = new fullpage('#service-page', {
    verticalCentered : false,
    sectionsColor : ['#bdd7ee', '#bdd7ee'],
    navigation: true,
    navigationPosition: 'right',
    afterRender: function(){
        var windoHeight = $( window ).width();
        if (windoHeight <= '375') {
            $('html').css('font-size', '12px');
            $('#service-page .section.service .left-wrapper img').css('width', '30%');
            $('#service-page .section .image-description img').css('width', '80%');
        }
    },
    onSlideLeave: function(){
        $('.slide [data-aos]').each(function(){
            $(this).removeClass("aos-animate")
        });
    },
    afterSlideLoad: function(){
        $('.slide.active [data-aos]').each(function(index, item){
            $(this).addClass("aos-animate")
        });
    },
    afterLoad: function() {
        $('.section.active [data-aos]').each(function () {
            $(this).addClass("aos-animate")
        });
    },
});

var recruitPage = new fullpage('#recruit-page', {
    verticalCentered : false,
    navigation: true,
    sectionsColor : ['#f4b183', '#f4b183'],
    navigationPosition: 'right',
    afterRender: function(){
        var windoHeight = $( window ).width();
        if (windoHeight <= '375') {
            $('html').css('font-size', '12px');
        }
    },

    onSlideLeave: function(){
        $('.slide [data-aos]').each(function(){
            $(this).removeClass("aos-animate")
        });
    },
    afterSlideLoad: function(){
        $('.slide.active [data-aos]').each(function(index, item){
            $(this).addClass("aos-animate")
        });
    },
    afterLoad: function() {
        $('.section.active [data-aos]').each(function () {
            $(this).addClass("aos-animate")
        });
    },
});